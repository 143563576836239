<template>
	<MhView :isLoading="isLoading">
		
		<template v-if="$root.isSmallDevice">		
			<MobilePageTitle></MobilePageTitle>		
		</template>			
		
		<!-- mobile: rubrik titel bar -->
		<div class="border border--bottom row" v-if="$root.isSmallDevice">
			<div class="viewTitle vSpace vSpace--default hSpace hSpace--big col">
				<div class="viewTitle__searchWrapper">
					<input 
						class="viewTitle__searchInput" 
						type="text"
						:value="$route.params.searchString" 
						autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false"
						@keyup.enter="switchToSearchView( $event.target.value )" />
					<div class="viewTitle__searchIcon"></div>
					<br />
				</div>
			</div>
		</div>			
		
		<!-- main title -->
		<div class="hSpace hSpace--big vSpace vSpace--default border border--bottom">
			<h2 class="font font--sizeHuge">
				<!-- {{posts.length}} -->
				<span v-html="$root.getTranslatedValue('Suchergebnisse für')"></span>:
				{{searchString}} 
			</h2>
		</div>
		
		<!-- results -->
		<div class="post border border--bottom row" v-for="post in posts" :key="post.id">
			<div 	class="col col--3of12 col--sm-12of12" 
					:class="{'border border--right' : !$root.isSmallDevice}">
				<template v-if="!$root.isSmallDevice">
					<MhImage class="post__image" :imageObject="post.acf.image" mode="cover"></MhImage>
				</template>
				<template v-else>
					<MhImage class="post__image" :imageObject="post.acf.image" mode="image"></MhImage>
				</template>
			</div>
			<div 	class="post__textContentWrapper hSpace hSpace--medium vSpace vSpace--default col col--6of12 col--sm-12of12" 
					:class="{'border border--right' : !$root.isSmallDevice, 'vSpace--notBottom' : $root.isSmallDevice}">
				<h3 class="post__title font font--sizeBig" v-html="getTitle( post )"></h3>
				<div class="post__subTitle font font--sizeSmall font--uppercase" v-html="getSubTitle( post )"></div>
				<div class="post__excerpt" v-html="getExcerpt( post )"></div>
			</div>
			<div 	class="hSpace hSpace--big vSpace vSpace--default col col--3of12 col--sm-12of12">
				<span class="font font--sizeSmall font--uppercase" v-html="getAside( post )"></span>				
			</div>
			<a   	class="post__link noArrow" 
					:href="$root.getSingleLink(post)"></a>
		</div>
		
	</MhView>
</template>

<script>
	// @ is an alias to /src
	import MhView from '@/components/MhView/MhView.vue'
	import MhImage from '@/components/MhImage/MhImage.vue'
	import RestHandler from '@/components/RestHandler/RestHandler.js'
	import MobilePageTitle from '@/components/MobilePageTitle.vue'

	export default {
		name: 'searchView',
		components: {
			MhView,
			MhImage,
			MobilePageTitle,
		},
		mixins: [ RestHandler ],
		data() {
			return {
				isLoading: true,
				searchPostTypes: [
					'newsPosts',
					'teamPosts',
					'programmPosts',
					'projectPosts',
					'researchPosts',
					'publicationPosts',
					'pages',
				],
				validSearchFields: [
					'function',
					'postTitle__de',
					'postTitle__en',
					'text__de',
					'text__en',
					'rubrik__de',
					'rubrik__en',
					'makerName',
				],
				searchResults: [],
				posts: [],
				searchString: null,
			}
		},
		computed: {},
		methods: {
			checkPostFields( post ){
				const debugLog = false
				const postFields = post.acf
				const validSearchFields = this.validSearchFields
				const searchString = this.searchString
				const currentLang = this.$root.getCurrentLang()
				let   postIsValid;
				
				if(debugLog) console.log('%cSearchView checkPostFields()', 'font-weight: bold; margin-top: 1em;')
				if(debugLog) console.log('	postId: ' + post.id)
				if(debugLog) console.log('	postTitle: ' + post.title.rendered)
				
				// Nimm alle validen Felder des posts, 
				// alle anderen werden nicht berücksichtigt
				let postFields__validFields = {}
				this._.forEach( postFields, (value, key) => {
					if( validSearchFields.indexOf(key) != -1 ){
						postFields__validFields[key] = value
					}					
				})
				//if(debugLog) console.log('%c	postFields__validFields:', 'font-weight: bold;')
				if(debugLog) console.log('	postFields__validFields:')
				this._.forEach(postFields__validFields, (value, key) => {
					if(debugLog) console.log('		- ' + key)
				})
				
				// Wirf alle Felder raus, die der Sprache nicht entsprechen				
				let postFields__validLang = {}
				this._.forEach(postFields__validFields, (value, key) => {
					if( this._.endsWith(key, '__de') && currentLang == 'en' ){
						//
					}
					else if( this._.endsWith(key, '__en') && currentLang == 'de' ){
						//
					}else{
						postFields__validLang[key] = value
					}					
				})
				if(debugLog) console.log('	postFields__validLang:')
				this._.forEach(postFields__validLang, (value, key) => {
					if(debugLog) console.log('		- ' + key)
				})				
				
				// Wirf alle Felder raus, in denen der Suchstring nicht auftaucht
				let postFields__foundSearchString = {}
				this._.forEach(postFields__validLang, (value, key) => { 
					if( value.toLowerCase().search(searchString.toLowerCase()) != -1 ){					
						postFields__foundSearchString[key] = value
					}
				})		
				if(debugLog) console.log('	postFields__foundSearchString:')
				this._.forEach(postFields__foundSearchString, (value, key) => {
					if(debugLog) console.log('		- ' + key)
				})					

				// Wenn am Schluss noch Felder übrigbleiben gilt der Post als match
				if(this._.size(postFields__foundSearchString) > 0){
					postIsValid = true
				}
				else{
					postIsValid = false
				}

				if(debugLog) console.log('	postIsValid: ' + postIsValid)

				return postIsValid
			},
			startSearch( callback ){
				const debugLog 			= false
				const searchString 		= this.searchString
				const searchPostTypes 	= this.searchPostTypes
			
				this.posts = []
				
				if(searchString){
				
					if(debugLog) console.log('%cSearchView startSearch()', 'font-weight: bold; margin-top: 1em;')
					if(debugLog) console.log('	searchString: ' + searchString)
					if(debugLog) console.log('	search in postTypes:')
					
					let searchPostType;
					let searchUrl;
					let fetched = 0
					
					for( let i = 0; i < searchPostTypes.length; i++ ) {
						
						searchPostType = searchPostTypes[i]
						
						if(debugLog) console.log('		- ' + searchPostType)
						
						// fetch the search results per postType
						this.restHandler__fetch({
							action : 'GET',	
							route : '/wp-json/wp/v2/' + searchPostType,
							params : {
								search : searchString,
								per_page : 100,
							},
							callback : (response) => {
								let resultPosts = response.data;
								let resultPost;
								
								for (let i = 0; i < resultPosts.length; i++) {
									resultPost = resultPosts[i]
									if( this.checkPostFields( resultPost ) ){
										this.posts.push( resultPost )									
									}
								}				
								
								fetched = fetched+1;
								if( fetched == searchPostTypes.length ){
									callback()
								}
							},
						})	
					
					}
				
				}else{
					
					callback()
					
				}
				
			},			
			getTitle( post ) {
				let text = this.$root.getTranslatedAcfValue( post.acf, 'postTitle')
				return text
			},
			getSubTitle( post ){
				let text 
				if( post.type == 'team_post' ) text = post.acf.function
				if( post.type == 'publication_post' ) text = this.$root.getTranslatedAcfValue( post.acf, 'rubrik')
				if( post.type == 'research_post' ) text = this.$root.getTranslatedAcfValue( post.acf, 'rubrik')
				
				return this.$root.getTranslatedValue(text)
			},
			getExcerpt( post ){
				let text = this.$root.getTranslatedAcfValue( post.acf, 'text')
				
				if( text ){
					let strippedText = text.replace(/(<([^>]+)>)/ig,"")
					let truncateText = this._.truncate(strippedText, {
						length: 350,
						separator: ' ',
						omission: ' (...)',
					});
					return this.$root.trim(truncateText)
				}
				
			},
			getAside( post ) {
				const mapTitles = {
					news_post : 'News',
					team_post : 'Team',
					programm_post : 'Lehre',
					research_post : 'Forschung',
					publication_post : 'Publikation',
					project_post : 'Projekt',
					page : 'Seite',
				}
				let text = post.type
				if( mapTitles[post.type] ){
					text = mapTitles[post.type]
				}
				return this.$root.getTranslatedValue(text)
			},
			switchToSearchView( searchString ) {
				if( searchString ){
					this.$router.push({ 
						name: 'SearchView', 
						params: { 
							searchString : encodeURI(searchString) 
						}
					})					
				}else{
					this.$router.push({ 
						name: 'SearchView-Empty',
					})					
				}
			}
		},
		mounted() {

			this.searchString = this.$route.params.searchString
			this.startSearch( () => {
				this.isLoading = false
			})

		},			
	}
</script>

<style lang="less" scoped>	
	@import "../less/includes/grid.include.less";
	
	.post {
		position: relative;
		height: 15em;
		overflow: hidden;
		
		&__image {
			height: 100%;
		}
		&__textContentWrapper {
			//background-color: red;
			position: relative;
			
			&:after {
				position: absolute;
				left: 0; right: 0;
				bottom: 0;
				height: 2em;
				content: "";
				background-image: linear-gradient(to bottom, rgba(255,255,255,0) 0%, white 100%);
				border-bottom: 1em solid white;
			}
		}
		&__title {}
		&__subTitle {
			padding-top: 1em;
			&:empty { display: none; }			
		}
		&__excerpt {
			padding-top: 1.3em;
			white-space: pre-line;
		}
		&__link {
			//background-color: fade(red, 20);
			
			position: absolute;
			top: 0; left: 0; right: 0; bottom: 0;
		}
	}
	
	@media @mediaQuery__sm {
		.post {
			height: auto;
		}
		.post__image {
			
		}
	}
	
	// fixes: mobile safari zoom page if font-size is less 16px, 
	// see: https://stackoverflow.com/questions/2989263/disable-auto-zoom-in-input-text-tag-safari-on-iphone/13918061#13918061
	input, textarea { 
		font-size: initial;
	}	
</style>
